<script>


/**
 *  Rename  component
 */



 import {
  required,
} from "vuelidate/lib/validators";



export default {

    emits: ['confirm', 'cancel'],

    props: ['id','name', 'type'],
    components: {
        
    },

    validations: {
        name : {required},
    },

    

    data() {
      
        return {
            submitted : false,
        }
       
    },
    methods: {
        formSubmit() {
            this.submitted = true;     
            this.$v.$touch();     
            if (this.$v.$error == false) {     
                this.$emit('confirm', this.id, this.name, this.type)
            }
        },
    },

    created() {

    },

    mounted() {
       
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom01">New Name</label>
                            <input id="validationCustom01" v-model="name" type="text"
                                class="form-control" placeholder="" value="" :class="{
                                    'is-invalid': submitted && $v.name.$error,
                                }" />
                            <div v-if="submitted && $v.name.$error" class="invalid-feedback">
                                <span v-if="!$v.name.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                   
                   
                </div>
                 <!-- end row-->


                <div class="row">
                    <div class="col-md-6" >
                        <div class="d-flex flex-wrap gap-2" centered>
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>