<script>


/**
 *  Folder Edit component
 */



 import {
  required,
} from "vuelidate/lib/validators";

import Choices from "choices.js";


export default {

    emits: ['confirm', 'cancel'],

    props: ['folder', 'tags'],
    components: {
        
    },

    validations: {
        folder: {
            folder_name: { required },
            tag: { required },
        },
    },

    

    data() {
      
        return {
            submitted : false,
        }
       
    },
    methods: {
        formSubmit() {
            this.submitted = true;     
            this.$v.$touch();     
            if (this.$v.$error == false) {     
                this.$emit('confirm', this.folder)
            }
        },
    },

    created() {

    },

    mounted() {

        this.tags = [
            {label : "Platform", value : 'Platform'},
            {label : "Company", value : 'Company'},
        ]
        console.log(this.tags)
        new Choices('#tag', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        }).setChoices(this.tags);
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom01">Folder Name</label>
                            <input id="validationCustom01" v-model="folder.folder_name" type="text"
                                class="form-control" placeholder="" value="Mark" :class="{
                                    'is-invalid': submitted && $v.folder.folder_name.$error,
                                }" />
                            <div v-if="submitted && $v.folder.folder_name.$error" class="invalid-feedback">
                                <span v-if="!$v.folder.folder_name.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom03">Folder Category</label>
                            <select  class="form-control"  data-trigger
                                v-model="folder.tag"
                                name="tag"
                                id="tag"
                                :class="{
                                    'is-invalid': submitted && $v.folder.tag.$error,
                                }"
                            >
                            <option value=""></option>
                            </select>
                        </div>
                    </div>
                   
                </div>
                 <!-- end row-->


                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>