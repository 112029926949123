<script>
import vue2Dropzone from "vue2-dropzone";

import appConfig from "@/app.config";

import 'vue2-dropzone/dist/vue2Dropzone.min.css';

/**
 * Form Uploads component
 */
export default {


    emits: ['confirm', 'cancel'],
page: {
    title: "Form File Upload",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {

      dropzoneOptions: {
        url: "/AwsFileUpload/s3_upload_multi",
        thumbnailWidth: 150,
        maxFilesize: 10,
      },

      files : []
    };
  },

  methods: {

    fileUploaded(ev, resp) {
        console.log("uploaded" , ev.upload, ev, resp)
        if (resp[0].errCode == 200) {
            this.files.push(resp[0].response)
        }
    },
    
    fileAdded() {
      
    } ,
    sendFiles() {
      console.log(this.files)
      this.$emit('confirm', this.files)
    }
 
   
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
};
</script>

<template>
  
   
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">Dropzone</h4>
        <a href="https://www.npmjs.com/package/vue2-dropzone" target="_blank" class="btn btn-sm btn-soft-secondary">Docs <i class="mdi mdi-arrow-right align-middle"></i></a>
      </div>
      <div class="card-body">
        <vue-dropzone
          id="dropzone"
          ref="myVueDropzone"
          :use-custom-slot="true"
          :options="dropzoneOptions"
          @vdropzone-file-added="fileAdded"
          @vdropzone-success="fileUploaded"
         
        >
          <div class="dropzone-custom-content">
            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
            <h4>Drop files here or click to upload.</h4>
          </div>
        </vue-dropzone>
        <div class="text-center mt-4">
          <button type="button" class="btn btn-primary" @click="sendFiles">Send Files</button>
        </div>
      </div>
    </div>

</template>
