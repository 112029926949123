<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import RenameDialog from './components/rename_dialog.vue'
import FolderEditDialog from './components/folder_edit_dialog.vue'
import FileUploadDialog from './components/file_upload_dialog.vue'
import { getBrokerageApi } from "@/api/brokerage";

/**
 * File-manager component
 */
export default {
  page: {
    title: "Docs & Forms",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Docs & Forms",
      items: [
        {
          text: "Brokerage",
        },
        {
          text: "Document and Form",
          active: true,
        },
      ],
      series: [76],
      chartOptions: {
        chart: {
          height: 150,
          type: "radialBar",
          sparkline: {
            enabled: true,
          },
        },
        colors: ["#038edc"],

        grid: {
          padding: {
            top: -10,
          },
        },
        labels: ["Storage"],
      },
      folderList: [],
      tags: [],
      fileData: [],
      folder: {
        folder_name: "",
        tag: ""
      },
      ff_name: "",
      ff_type: "",
      ff_id: "",
      current_folder: {}
    };
  },
  components: {
    Layout,
    PageHeader,
    FolderEditDialog,
    FileUploadDialog,
    RenameDialog
  },

  methods: {
    folderChange(folder) {
    
      this.current_folder.is_open = false
      folder.is_open = true
      this.fileData = folder.files
      this.fileData.map((f) => { f.name = f.file_name; f.size = f.file_size; })
      this.current_folder = folder
      if (!this.current_folder.files) {
        this.current_folder.files = []
      }

    },

    folder_save(newFolder) {
      getBrokerageApi().docApi.folderSave(newFolder).then((res) => {
        if (res.errCode == 0) {
          newFolder.id = res.data.id
          newFolder.name = newFolder.folder_name + '(' + newFolder.tag + ')'
          this.folderList.map((folder) => {
          
              if (folder.value == newFolder.tag) {
                folder.subFolders.push(newFolder)
              }
          })
          this.$bvModal.hide('modal-folder-edit-dialog')
          this.folder = {
            folder_name: "",
            tag: ""
          }
        } else {
          this.$alertify.error("Save Folder Failed ");
        }

      })

    },


    files_save(files) {
      let newFiles = []
      files.map((f) => {
        newFiles.push({
          folder_id: this.current_folder.id,
          file_name: f.filename,
          file_url: f.url,
          file_size: f.size
        })
      })
      let data = {
        files: newFiles
      }
      getBrokerageApi().docApi.filesSave(data).then((res) => {
        if (res.errCode == 0) {
          if (this.current_folder) {
            this.current_folder.files.push(...res.data)
            this.fileData = this.current_folder.files
            this.fileData.map((f) => { f.name = f.file_name; f.size = f.file_size; })
          }
          this.$bvModal.hide('modal-file-upload-dialog')
        } else {
          this.$alertify.error("Save Folder Failed ");
        }

      })
    },

    fileRemove(f) {
      let data = {
        files: [{ id: f.id }]
      }
      getBrokerageApi().docApi.filesDelete(data).then((res) => {
        if (res.errCode == 0) {
          if (this.current_folder) {
            this.current_folder.files = this.current_folder.files.filter((e) => e.id != f.id)
            this.fileData = this.current_folder.files
            this.fileData.map((f) => { f.name = f.file_name; f.size = f.file_size; })
          }

        } else {
          this.$alertify.error("Remove File Failed ");
        }
      })
    },

    fileRename(f) {
      this.ff_id = f.id
      this.ff_name = f.file_name
      this.ff_type = 'FILE'
      this.$bvModal.show('modal-rename-dialog')
    },
    folderRename(f) {
      this.ff_id = f.id
      this.ff_name = f.folder_name
      this.ff_type = 'FOLDER'
      this.$bvModal.show('modal-rename-dialog')
    },

    nameSave(id, name, type) {

      let data = { id: id, name: name, type: type }
      getBrokerageApi().docApi.fileFolderRename(data).then((res) => {
        if (res.errCode == 0) {
          let f = this.current_folder.files.find((e) => e.id == id)
          if (f) {
            f.name = data.name
          }
          this.fileData = this.current_folder.files
          this.$bvModal.hide('modal-rename-dialog')
        } else {
          this.$alertify.error("Rename  Failed ");
        }
      })

    }
  },

  created() {
    getBrokerageApi().docApi.folderTree().then((res) => {
      console.log(res)
      if (res.errCode == 0) {
        let keys = Object.keys(res.data)

        keys.map((tag) => {
          this.tags.push({
            label: tag,
            value: tag,
            subFolders: res.data[tag]
          })
         
        })
        this.folderList = this.tags
       
      }

     

    })
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-xl-flex">
      <div class="w-100">
        <div class="d-md-flex">
          <div class="card filemanager-sidebar me-md-3">
            <div class="d-flex flex-column h-100">

              <ul class="list-unstyled categories-list">
                <li v-for="(folder, idx) in folderList" :key="folder + '_' + idx"
                  :class="{ unread: current_folder.id == folder.id ? true : false }">
                  <div class="custom-accordion" :value="folder">
                    <a class="text-body fw-medium py-1 d-flex align-items-center" v-b-toggle="'folder_' + idx"
                      href="javascript:void(0);" :id="folder.value" :value="folder">
                      <i class="fas text-warning font-size-13 me-2"
                        :class="folder.is_open == true ? 'fa-folder-open' : 'fa-folder'"></i>
                      {{ folder.label }}
                    </a>

                    <b-collapse visible class="collapse" :id="'folder_' + idx">
                      <div class="card border-0 shadow-none ps-2 mb-0">
                        <ul class="list-unstyled mb-0">
                          <a class="text-body fw-medium py-1 d-flex align-items-center" href="javascript:void(0);"
                            :id="folder.value" :value="subFolder" v-for="(subFolder, fidx) in folder.subFolders"
                            :key="subFolder.id + '_' + fidx" @click="folderChange(subFolder)"
                            >
                            <i class="fas text-warning font-size-13 me-2"
                              :class="subFolder.is_open == true ? 'fa-folder-open' : 'fa-folder'"></i>
                            {{ subFolder.folder_name }}
                          </a>

                        </ul>
                      </div>
                    </b-collapse>

                  </div>

                </li>


              </ul>

            </div>
          </div>
          <!-- end card -->
          <!-- filemanager-leftsidebar -->

          <div class="w-100">
            <div class="card">
              <div class="card-body">
                <div>
                  <div class="row mb-3">
                    <div class="col-lg-4 col-sm-6">
                      <div class="search-box mb-2">
                        <div class="position-relative">
                          <input type="text" class="form-control bg-light border-light rounded" placeholder="Search..." />
                          <i class="uil uil-search search-icon"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-8 col-sm-6">
                      <div class="mt-4 mt-sm-0 d-flex align-items-center justify-content-sm-end">
                        <div class="mb-2 me-2">
                          <b-dropdown variant="primary" class="dropdown" right>
                            <template v-slot:button-content>
                              <i class="uil uil-plus me-1"></i> Create New
                            </template>

                            <b-dropdown-item v-b-modal.modal-folder-edit-dialog><i class="uil uil-folder me-1"></i>
                              Folder</b-dropdown-item>
                            <b-dropdown-item v-b-modal.modal-file-upload-dialog><i class="uil uil-file me-1"></i>
                              File</b-dropdown-item>
                          </b-dropdown>
                        </div>



                        <b-modal centerd id="modal-folder-edit-dialog" title="Folder Info" size="lg" hide-footer>
                          <FolderEditDialog :folder="folder" :tags="tags"
                            @cancel="$bvModal.hide('modal-folder-edit-dialog')" @confirm="folder_save" />
                        </b-modal>

                        <b-modal centerd id="modal-file-upload-dialog" title="Folder Info" size="lg" hide-footer>
                          <FileUploadDialog :folder="folder" :tags="tags"
                            @cancel="$bvModal.hide('modal-file-upload-dialog')" @confirm="files_save" />
                        </b-modal>

                        <b-modal centerd id="modal-rename-dialog" title="Rename" hide-footer>
                          <RenameDialog :id="ff_id" :name="ff_name" :type="ff_type"
                            @cancel="$bvModal.hide('modal-rename-dialog')" @confirm="nameSave" />
                        </b-modal>

                      </div>
                    </div>
                  </div>
                </div>




                <div class="mt-4">
                  <hr class="mt-2" />

                  <div class="table-responsive">
                    <table class="table align-middle table-nowrap table-hover mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Date Modified</th>
                          <th scope="col" colspan="2">Size</th>
                        </tr>
                        <!-- end tr -->
                      </thead>
                      <!-- end thead -->
                      <tbody>
                        <tr v-for="(item, index) in fileData" :key="index">
                          <td>
                            <a href="javascript: void(0);" class="text-dark fw-medium"><i
                                :class="`${item.icon} align-middle me-2`"></i>
                              {{ item.name }}</a>
                          </td>
                          <td>{{ item.date }}</td>
                          <td>{{ item.size }}</td>
                          <td>
                            <b-dropdown variant="white" toggle-class="font-size-16 text-muted p-0">
                              <template v-slot:button-content>
                                <i class="uil uil-ellipsis-h"></i>
                              </template>
                              <b-dropdown-item @click="fileRename(item)">Rename</b-dropdown-item>
                              <b-dropdown-item @click="fileRemove(item)">Remove</b-dropdown-item>
                            </b-dropdown>
                          </td>
                        </tr>
                        <!-- end tr -->
                      </tbody>
                      <!-- end tbody -->
                    </table>
                    <!-- end table -->
                  </div>
                  <!-- end table responsive -->
                </div>
              </div>
              <!-- end cardbody-->
            </div>
            <!-- end card -->
          </div>
          <!-- end w-100 -->
        </div>
      </div>

    </div>
    <!-- end row -->
  </Layout>
</template>
